import { useContext } from 'react';

import {
	useReverseTrialCohort,
	ReverseTrialCohort,
} from '@confluence/trial-components/entry-points/useReverseTrialEligible'; // adjust the path as needed
import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';

import {
	type PageRestrictionsExperimentCohort,
	getPageRestrictionsExperimentCohort,
} from './getPageRestrictionsExperimentCohort';

export const usePageRestrictionsExperimentCohort = (
	fireExperimentExposure: boolean = false,
): PageRestrictionsExperimentCohort | undefined => {
	const { edition } = useSessionData();
	const isFreeEdition = ConfluenceEdition.FREE === edition;

	const { isSiteAdmin } = useContext(SPAViewContext);

	const {
		reverseTrialCohort,
		loading: reverseTrialLoading,
		error: reverseTrialError,
	} = useReverseTrialCohort({
		skip: !isFreeEdition || !isSiteAdmin || Boolean(process.env.REACT_SSR),
	});

	const isInReverseTrial = reverseTrialCohort === ReverseTrialCohort.ENROLLED;

	if (
		!isFreeEdition ||
		!isSiteAdmin ||
		Boolean(process.env.REACT_SSR) ||
		reverseTrialLoading ||
		reverseTrialError ||
		!reverseTrialCohort ||
		isInReverseTrial
	) {
		return undefined;
	}
	return getPageRestrictionsExperimentCohort(fireExperimentExposure);
};
