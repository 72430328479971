import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { ConfluenceEdition } from '@confluence/change-edition';

import { SignUpProperties } from './graphql/SignUpPropertiesQuery.graphql';
import type { SignUpProperties as SignUpPropertiesType } from './graphql/__types__/SignUpProperties';
import { ReverseTrialCohort } from './graphql/__types__/SignUpProperties';

export const useReverseTrialEligible = () => {
	const { edition } = useSessionData();
	const isPremium = edition === ConfluenceEdition.PREMIUM;
	const { isSiteAdmin } = useContext(SPAViewContext);

	// SignUpProperties that are sent from BXP
	const { reverseTrialCohort, loading, error } = useReverseTrialCohort({
		skip: !isSiteAdmin || !isPremium,
	});
	// Master FF to quickly enable / disable all Onboarding Experiences
	const isOnboardingEligible = useBooleanFeatureFlag('confluence.frontend.onboarding.experience');
	const isReverseTrialEligibleUser = isSiteAdmin && isPremium && isOnboardingEligible;

	const isReverseTrialTreatmentCohort =
		!loading && !error && reverseTrialCohort === ReverseTrialCohort.ENROLLED;

	return {
		isReverseTrialEligible: isReverseTrialEligibleUser && isReverseTrialTreatmentCohort,
	};
};

export function useReverseTrialCohort({
	skip,
}: { skip?: boolean; fireExperimentExposure?: boolean } = {}) {
	const { edition } = useSessionData();
	const { data, loading, error } = useQuery<SignUpPropertiesType>(SignUpProperties, {
		skip,
	});

	const isReverseTrialEnrolledVersion: boolean =
		data?.signUpProperties?.reverseTrial === ReverseTrialCohort.ENROLLED &&
		edition === ConfluenceEdition.PREMIUM;

	return {
		reverseTrialCohort: data?.signUpProperties?.reverseTrial,
		loading: skip ? false : loading,
		error: skip ? null : error,
		isReverseTrialEnrolledVersion,
	};
}
