/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ReverseTrialCohort {
  CONTROL = "CONTROL",
  ENROLLED = "ENROLLED",
  NOT_ENROLLED = "NOT_ENROLLED",
  UNASSIGNED = "UNASSIGNED",
  UNKNOWN = "UNKNOWN",
  VARIANT = "VARIANT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SignUpProperties
// ====================================================

export interface SignUpProperties_signUpProperties {
  reverseTrial: ReverseTrialCohort | null;
}

export interface SignUpProperties {
  signUpProperties: SignUpProperties_signUpProperties | null;
}
