import { useEffect, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useIsExternalCollaborator } from './useIsExternalCollaborator';

export enum EXTERNAL_COLLAB_EXPERIMENT_COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	VARIATION = 'variation',
}

type CohortType = EXTERNAL_COLLAB_EXPERIMENT_COHORTS | string;

type ExternalCollabExperimentParams = {
	flagKey: string;
	source: string;
	additionalEnrollmentCriteria?: boolean;
	touchpoint?: string;
	additionalCohorts?: CohortType[];
};

type ExternalCollabExperiment = {
	isGuest: boolean;
	cohort: CohortType;
	shouldRenderExperiment: boolean;
};

export const useExternalCollabExperiment = ({
	flagKey,
	source,
	additionalEnrollmentCriteria = true,
	touchpoint,
}: ExternalCollabExperimentParams): ExternalCollabExperiment => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [cohort, setCohort] = useState<CohortType>(EXTERNAL_COLLAB_EXPERIMENT_COHORTS.NOT_ENROLLED);

	const { isExternalCollaborator: isGuest } = useIsExternalCollaborator();
	const meetsEnrollmentCriteria = additionalEnrollmentCriteria && isGuest;

	useEffect(() => {
		if (!meetsEnrollmentCriteria) {
			setCohort(EXTERNAL_COLLAB_EXPERIMENT_COHORTS.NOT_ENROLLED);
			return;
		}

		const cohort = FeatureGates.getExperimentValue<CohortType>(
			flagKey,
			'cohort',
			EXTERNAL_COLLAB_EXPERIMENT_COHORTS.NOT_ENROLLED,
			{
				fireExperimentExposure: false,
			},
		);
		setCohort(cohort);

		if (cohort !== EXTERNAL_COLLAB_EXPERIMENT_COHORTS.NOT_ENROLLED) {
			// Statsig exposure event
			FeatureGates.manuallyLogExperimentExposure(flagKey);

			// Regular exposure event for sanity check
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					actionSubject: 'feature',
					action: 'exposed',
					source,
					attributes: {
						flagKey,
						flagValue: cohort,
						touchpoint,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, meetsEnrollmentCriteria, source, touchpoint, flagKey]);

	return {
		isGuest,
		cohort,
		shouldRenderExperiment: cohort === EXTERNAL_COLLAB_EXPERIMENT_COHORTS.VARIATION,
	};
};
