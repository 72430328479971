import FeatureGates from '@atlaskit/feature-gate-js-client';

const CC_PAGE_RESTRICTIONS_UPSELL_EXPERIMENT_FF = 'cc_page_restrictions_upsell_design';

export type PageRestrictionsExperimentCohort = 'test' | 'control' | 'none';

// returns the experiment cohort
export const getPageRestrictionsExperimentCohort = (
	fireExperimentExposure: boolean = false,
): PageRestrictionsExperimentCohort => {
	const ccPageRestrictionsExperiment = FeatureGates.getExperiment(
		CC_PAGE_RESTRICTIONS_UPSELL_EXPERIMENT_FF,
		{
			fireExperimentExposure,
		},
	);

	return ccPageRestrictionsExperiment.get(
		'cohort',
		'none',
		(value: unknown): value is PageRestrictionsExperimentCohort =>
			value === 'none' || value === 'test' || value === 'control',
	);
};
