import React from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import type { Appearance as SpinnerAppearance } from '@atlaskit/spinner';
import Spinner from '@atlaskit/spinner';
import type { PresetSize } from '@atlaskit/spinner/types';
import { FadeIn } from '@atlaskit/motion';
import UFOInteractionIgnore from '@atlaskit/ufo-interaction-ignore';

export type SSRActionLoadingSpinnerProps = {
	actionType:
		| 'restrictionsButton'
		| 'watchButton'
		| 'favoriteButton'
		| 'inlineCommentButton'
		| 'pageShareAndPermsButton'
		| 'moreActionButton'
		| 'spaceStarTextButton'
		| 'dismissAdminAnnouncementBannerButton';
	spinnerId: string;
	showSpinner?: boolean;
	spinnerSize?: PresetSize;
	style?: string;
	spinnerAppearance?: SpinnerAppearance;
};
type LoadingSpinnerContainerProps = {
	showSpinner?: boolean;
	styleOverride?: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LoadingSpinnerContainer = styled.div<LoadingSpinnerContainerProps>`
	display: ${({ showSpinner }) => (showSpinner ? `flex` : `none`)};
	font-size: 14px;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	${({ styleOverride }) => styleOverride ?? ''}
`;

export const SSRActionLoadingSpinner = ({
	actionType,
	spinnerId,
	showSpinner,
	spinnerSize = 'small',
	spinnerAppearance,
	style,
}: SSRActionLoadingSpinnerProps) => {
	const showSpinnerButton = Boolean(
		(window.__SSR_EVENTS_CAPTURE__ && window.__SSR_EVENTS_CAPTURE__[`${actionType}`]) ||
			showSpinner,
	);
	const mountTime = React.useRef(Date.now() % 860);
	return (
		<UFOInteractionIgnore ignore={!showSpinnerButton}>
			<LoadingSpinnerContainer
				data-id={spinnerId}
				showSpinner={showSpinnerButton}
				data-testid={spinnerId}
				styleOverride={style}
			>
				<FadeIn>
					{(props) => (
						<Spinner
							size={spinnerSize}
							delay={-mountTime.current}
							appearance={spinnerAppearance}
							{...props}
							testId={`${spinnerId}-spinner`}
						/>
					)}
				</FadeIn>
			</LoadingSpinnerContainer>
		</UFOInteractionIgnore>
	);
};
